import React from 'react';
import { SplashText } from '../../../types/cppQuiz';


interface Props {
  setIsSplashScreenOpen: (val: boolean) => void;
  splashText: SplashText;
}
export default function SplashScreen({ setIsSplashScreenOpen, splashText }: Props) {
  return (
    <div className="w-full md:mt-16 font-montserrat flex items-center justify-center flex-col text-white">
      <div className="w-[80%]">
        <h1 className="text-4xl text-center font-bold">{splashText.title}</h1>
        <p className="text-center w-full mb-4 py-3 rounded-lg bg-opacity-60 my-8">
          {splashText.body}
        </p>
        <button
          onClick={() => setIsSplashScreenOpen(false)}
          className="mt-6 font-semibold font-montserrat text-sm w-full ring-1 bg-gray-800 hover:bg-gray-800/30 mb-4 py-3 rounded-lg cursor-pointer text-white bg-opacity-60"
        >Begin</button>
      </div>
    </div>
  );
}

