import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/16/solid';

export default function QuizResultBadge({ correct }: { correct: boolean }) {
  return (
    <div>
      {correct
       ? <div className="h-full ring-1 ring-green-300 flex gap-1 font-medium font-montserrat bg-green-200 text-green-800 rounded-md py-1 px-2 items-center justify-center">
          <CheckIcon className="w-7 text-green-600"></CheckIcon>
        </div>
        :
        <div className="h-full ring-1 ring-red-300 flex gap-1 font-medium font-montserrat bg-red-200 text-red-800 rounded-md py-1 px-2 items-center justify-center">
          <XMarkIcon className="w-7 text-red-600"></XMarkIcon>
        </div>
      }
    </div>
  );
}