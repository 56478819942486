import React from 'react';
import './App.css';
import { CppQuiz } from './app/components/cppQuiz/CppQuiz';
import { useFetch } from './app/hooks/useFetch';
import { RemoteConfigurations } from './RemoteConfigurations';
import { AppAnnouncement } from './app/components/announcement/AppAnnouncement';
import configuration from './configurations/configurations';

function App(): React.JSX.Element {
  const { data: appConfig, loading, error } = useFetch<RemoteConfigurations>(configuration.appConfigUri);

  function isValidRemoteConfigurations(obj: any): obj is RemoteConfigurations {
    return obj
      && typeof obj?.announcement !== 'undefined'
      && typeof obj?.splashText !== 'undefined'
      && typeof obj?.quizzes !== 'undefined';
  }

  if (loading) {
    return <></>;
  }

  if (!isValidRemoteConfigurations(appConfig) || appConfig?.quizzes?.length < 1 || error) {
    return <div className="w-screen h-screen flex items-center justify-center font-montserrat font-bold">Website is currently under maintenance . . . 💻</div>;
  }


  return (
    <div className="relative isolate bg-gray-900 min-w-screen min-h-screen">
      <svg
        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
      </svg>

     <main className="flex items-center justify-center flex-col">
       <AppAnnouncement announcement={appConfig.announcement}></AppAnnouncement>
        <section className="pt-[3rem] md:pt-[5rem] max-w-[640px] min-w-full sm:min-w-[640px]">
          <CppQuiz remoteConfigurations={appConfig}></CppQuiz>
        </section>
     </main>
    </div>
  );
}

export default App;
