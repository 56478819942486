import { CppQuiz } from '../../../types/cppQuiz';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import ProgressBar from '@ramonak/react-progress-bar';
import './css/CppQuiz.css'

interface Props {
  question: CppQuiz;
  currentQuestionIndex: number;
  questionCount: number;
  onAnswerSelected: (answer: number) => void;
}

export function CppQuizQuestion({ question, currentQuestionIndex, questionCount, onAnswerSelected }: Props) {

  const handleOptionChange = (val: number) => {
    onAnswerSelected(val);
  };

  return (
    <section className="flex flex-col items-center justify-center h-full w-full px-4">
      <div className="w-full">
        <h1 className="text-2xl md:text-4xl text-center backdrop-blur-lg text-white font-montserrat font-bold">{ question.question }</h1>

        <ProgressBar
          height="17px"
          labelSize="13px"
          className="my-7"
          bgColor="#4E47E0"
          completed={(currentQuestionIndex/questionCount)*100}
          customLabel={`${currentQuestionIndex}/${questionCount}`}
        />

        <div className="overflow-hidden rounded-lg text-sm">
          <SyntaxHighlighter
            lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
            wrapLines={true}
            language="cpp"
            style={atomOneDark}>
            {question.code}
          </SyntaxHighlighter>
        </div>
        <form className="w-full text-center mt-5 font-montserrat">
          {question.options.map((entry, index) => (
            <div key={Math.random()} onClick={() => handleOptionChange(index)} className="font-semibold w-full ring-1 bg-gray-800 hover:bg-gray-800/30 mb-4 py-3 rounded-lg cursor-pointer text-white bg-opacity-60">
              {entry.option}
            </div>
          ))}
        </form>
      </div>
    </section>
  );
}