import { CppQuizAnnouncement } from '../../../types/cppQuiz';
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';

export function AppAnnouncement({announcement}: {announcement: CppQuizAnnouncement}) {
  const [showAnnouncement, setShowAnnouncement] = useState<boolean>(false);

  useEffect(() => {
    const isAnnouncementDismissed = localStorage.getItem('announcementDismissed') === 'true';
    setShowAnnouncement(!isAnnouncementDismissed && announcement.show);
  }, [announcement.show]);

  const dismissAnnouncement = () => {
    localStorage.setItem('announcementDismissed', 'true');
    setShowAnnouncement(false);
  };

  if (!showAnnouncement) {
    return null;
  }

  if (!announcement.show) {
    return <></>;
  }

  return (
    <div className="flex items-center w-full gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <strong className="font-semibold">Cpp Quiz</strong>
        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
          <circle cx={1} cy={1} r={1} />
        </svg>
        {announcement.text}
      </p>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={dismissAnnouncement}>
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}