import { QuizOption, QuizResult } from '../../../types/cppQuiz';
import React, { useState } from 'react';
import QuizResultPage from '../quizResult/QuizResult';
import { CppQuizQuestion } from './CppQuizQuestion';
import { scrollToNextQuestion } from '../../../lib/scroll';
import SplashScreen from '../splashScreen/SplashScreen';
import { RemoteConfigurations } from '../../../RemoteConfigurations';

export function CppQuiz({ remoteConfigurations}: { remoteConfigurations: RemoteConfigurations }) {
  const { quizzes, splashText } = remoteConfigurations;
  const [isSplashScreenOpen, setIsSplashScreenOpen] = useState<boolean>(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<{[key: string]: number}>({});


  const handleAnswerSelection = (answerId: number): void => {
    setAnswers({
      ...answers,
      [currentQuestionIndex]: answerId
    });

    if(currentQuestionIndex !== quizzes!.length - 1) {
      setCurrentQuestionIndex((prev: number) => prev + 1);
    }

    scrollToNextQuestion()
  };

  const findOptionTextByOptionId = (optionId: number, options: QuizOption[]): string | undefined => {
    return options.find((option: QuizOption) => option.id === optionId)?.option;
  }

  const generateQuizResult = (): QuizResult[] => {
    return Object.keys(answers).map((key: string) => {
      const correctAnswerId: number = quizzes![parseInt(key)].answer.id;
      const selectedAnswerId: number = answers[key];
      const userAnswerOptionText: string = findOptionTextByOptionId(selectedAnswerId, quizzes![parseInt(key)].options) || '';
      const correctAnswerOptionText: string = findOptionTextByOptionId(correctAnswerId, quizzes![parseInt(key)].options) || '';

      return {
        question: quizzes![parseInt(key)].question,
        explanation: quizzes![parseInt(key)].answer.explanation,
        correct: selectedAnswerId === correctAnswerId,
        correctAnswerOptionText: correctAnswerOptionText,
        userAnswerOptionText: userAnswerOptionText,
        code: quizzes![parseInt(key)].code,
      };
    });
  }

  if (Object.keys(answers).length === quizzes!.length) {
    return <QuizResultPage quizResults={generateQuizResult()}></QuizResultPage>
  }

  if (isSplashScreenOpen) {
    return <SplashScreen setIsSplashScreenOpen={setIsSplashScreenOpen} splashText={splashText}></SplashScreen>
  }

  return (
    <CppQuizQuestion
      question={quizzes[currentQuestionIndex]}
      currentQuestionIndex={currentQuestionIndex}
      questionCount={quizzes.length}
      onAnswerSelected={handleAnswerSelection}
    ></CppQuizQuestion>
  );
}