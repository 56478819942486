import { QuizResult } from '../../../types/cppQuiz';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import React from 'react';
import QuizResultBadge from './QuizResultBadge';

export default function QuizResultPage({ quizResults }: { quizResults: QuizResult[] }) {
  const correctAnswers: number = quizResults.filter((result: QuizResult) => result.correct).length;

  return (
    <section className="flex flex-col items-center justify-center h-full w-full px-4">
      <div className="w-full">
        <h1 className="text-2xl md:text-4xl text-center backdrop-blur-lg text-white font-montserrat font-bold">You scored</h1>
        <div className="flex flex-col items-center justify-center my-5">
          <p className="text-6xl text-white font-montserrat font-bold">{correctAnswers} / {quizResults.length} {correctAnswers === quizResults.length && '🎊'}</p>
        </div>

        {/* Result cards */}
        {quizResults.map((result: QuizResult, index: number) => {
          return (
            <div key={Math.random()} className="bg-gray-100/90 backdrop-blur-sm rounded-md mb-6 p-3 ring-1">
              <div className="w-full flex justify-between mb-2">
                <p className="font-montserrat text-gray-800 text-xl font-bold">{index+1}. {result.question}</p>
              </div>

              <div className="flex gap-4 my-4">
                <QuizResultBadge correct={result.correct} />
                <div className="font-montserrat">
                  <p className="text-sm font-medium text-gray-500">You answered</p>
                  <p className="font-bold">{result.userAnswerOptionText}</p>
                </div>
              </div>


              <div className="bg-gray-100 rounded-md p-2 mt-2 font-montserrat text-sm shadow-lg ring-1 ring-gray-300">
                {!result.correct &&
                    <div className="font-montserrat text-mdtext-gray-800 flex mb-3">
                      <p className="mr-2 font-medium text-gray-600">💡 Correct answer </p>
                      <p className="font-bold">{result.correctAnswerOptionText}</p>
                    </div>
                }
               <p className="font-medium">{result.explanation}</p>
              </div>

              <div className="overflow-hidden rounded-lg text-xs mt-4">
                <SyntaxHighlighter
                  lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                  wrapLines={true}
                  language="cpp"
                  style={atomOneDark}>
                  {result.code}
                </SyntaxHighlighter>
              </div>
            </div>
          );
        })}

      </div>
    </section>
  );
}